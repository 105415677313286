import { useEffect, useState } from "react";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useParams } from "react-router-dom";

import { AUTO_HIDE_DURATION_FOR_PLAY_RESULT_NOTIFICATION_IN_MS } from "../constants";
import { CardOnTimeline } from "./CardOnTimeline";
import { CardModel, Cards } from "../models/CardModel";
import { TableNotifications } from "./TableNotifications";
import { PlayerModel } from "../models/PlayerModel";
import useWebSocket from "react-use-websocket";
import { EventNames } from "../models/EventNames";
import { getUrlForTableWsConnection } from "../utils";
import { IconButton } from "@mui/material";

function Timeline({
  cards, isFirstTimeShowingTimelineOnRound, currentWindowIndex, numberOfWindows,
  previousPlayer, currentPlayerCard, currentPlayer, isPlayableTable, playerSocketUrl, isTimeToPlay
} : {
    cards: Cards, isFirstTimeShowingTimelineOnRound: boolean,
    currentWindowIndex: number, numberOfWindows: number, previousPlayer: PlayerModel | undefined,
    currentPlayerCard: CardModel | undefined, currentPlayer: PlayerModel | undefined,
    isPlayableTable: boolean, playerSocketUrl: string | (() => string | Promise<string>) | null,
    isTimeToPlay: boolean
  }) {
  const { tableId, playerId } = useParams();
  const eventBus = document;
  const [cardsComponent, setCardsComponent] = useState<JSX.Element[]>([]);

  const [hasPreviousWindow, setHasPreviousWindow] = useState(true);
  const [hasNextWindow, setHasNextWindow] = useState(true);

  const [playerScored, setPlayerScored] = useState(false);
  const [pivotCardId, setPivotCardId] = useState(0);

  const [showingPlayResult, setShowingPlayResult] = useState(false);
  const [showingNotifications, setShowingNotifications] = useState(false);

  const [showingAppliedTimeoutPenalty, setShowingAppliedTimeoutPenalty] = useState(false);
  const [awaitingPlayerReconnection, setAwaitingPlayerReconnection] = useState(false);

  const [reconnectTimeLimit, setReconnectTimeLimit] = useState(0);

  const [playerCard, setPlayerCard] = useState<CardModel | undefined>();

  const urlForTableConnection = getUrlForTableWsConnection(tableId, playerId);
  const { sendJsonMessage, lastMessage } =
  useWebSocket(urlForTableConnection, {
    share: true,
    retryOnError: true,
    onClose: (e) => console.log('Websocket connection closed!', e),
    onError: (e) => console.log('Error on Websocket connection!', e)
  });

  const { sendJsonMessage: playerSendJsonMessage } = 
  useWebSocket(playerSocketUrl, {
    share: true,
    retryOnError: isPlayableTable,
    onClose: (e) => console.log('Websocket connection closed!', e),
    onError: (e) => console.log('Error on Websocket connection!', e)
  });

  const onShowPreviousWindow = () => {
    if (isTimeToPlay) {
      playerSendJsonMessage({type: 'showPreviousWindow', data: {}});
    } else {
      // TODO: show visual feedback 
    }
  }

  const onShowNextWindow = () => {
    if (isTimeToPlay)
      playerSendJsonMessage({type: 'showNextWindow', data: {}});
  }

  const hideAppliedTimeoutPenaltyNotification = () => {
    setShowingAppliedTimeoutPenalty(false);
    sendJsonMessage({type: 'ack', data: {"refersTo": EventNames.appliedTimeoutPenalty}});
  }

  useEffect(() => {
    setShowingNotifications(
      showingPlayResult || showingAppliedTimeoutPenalty ||
      awaitingPlayerReconnection)
  }, [showingPlayResult, showingAppliedTimeoutPenalty, awaitingPlayerReconnection])

  useEffect(() => {
    if (currentPlayerCard === undefined) return;
    setPlayerCard(currentPlayerCard)
  }, [currentPlayerCard])

  useEffect(() => {    
    const hidePlayResult = () => {
      setShowingPlayResult(false);
      sendJsonMessage({type: 'ack', data: {"refersTo": EventNames.endOfPlayerTurn}})
    }

    const processNonAck = (evt: any) => {
      let evtName = evt.type
      if(evtName !== EventNames.ping &&
        evtName !== EventNames.awaitingPlayerGuess) console.log(evt)
  
      switch(evtName) {
        case EventNames.endOfPlayerTurn: {
          var scored = false;
          if (evt.data.scored === 'true') scored = true;
          setPlayerScored(scored);

          setShowingPlayResult(true);
          setTimeout(hidePlayResult, AUTO_HIDE_DURATION_FOR_PLAY_RESULT_NOTIFICATION_IN_MS);
          setAwaitingPlayerReconnection(false);

          break;
        }
        case EventNames.awaitingPlayerReconnection: {
          let _reconnectTimeLimit = JSON.parse(evt.data.reconnectTimeLimit)
          setAwaitingPlayerReconnection(true)
          setReconnectTimeLimit(_reconnectTimeLimit)
          break;
        }
        case EventNames.playerReconnected: {
          setAwaitingPlayerReconnection(false)
          break;
        }
        case EventNames.appliedTimeoutPenalty: {
          setAwaitingPlayerReconnection(false);
          eventBus.dispatchEvent(new CustomEvent("custom:showAppliedTimeoutPenaltyNotification"));
          setShowingAppliedTimeoutPenalty(true);
          break;
        }
        case EventNames.startOfRound: {
          sendJsonMessage({type: 'ack', data: {"refersTo": EventNames.startOfRound}});
          break;
        }
        case EventNames.intermediateGuessMade: {
          setAwaitingPlayerReconnection(false);
          break;
        }
        case EventNames.showPartialTimeline: {
          const { pivotCardId } = evt.data;
          setPivotCardId(pivotCardId);
          break;
        }
      }
    }

    const processEvent = (eventJson: MessageEvent<any>) => {
      let event = JSON.parse(eventJson.data)
      let evtType = event.type 

      switch(evtType) {
        case "ack": {
          break;
        }
        default: {
          processNonAck(event)
          break;
        }
      }
    }
    
    if (lastMessage !== null) {
      processEvent(lastMessage)
    }
  }, [eventBus, lastMessage, sendJsonMessage])

  useEffect(() => {
    if (currentWindowIndex !== 0) setHasPreviousWindow(true);
    else setHasPreviousWindow(false);

    if (numberOfWindows > 1 && currentWindowIndex+1 !== numberOfWindows) setHasNextWindow(true);
    else setHasNextWindow(false);
  }, [currentWindowIndex, numberOfWindows])

  useEffect(() => {
    const renderTimeline = (withDelay: boolean) => {
      setCardsComponent(cards.map((card, idx) => {
        return (
        <li key={card.id}>
          <CardOnTimeline
            cardId={card.id}
            description={card.description}
            year={card.year}
            nameEN={card.nameEN}
            namePT={card.namePT}
            idx={idx}
            renderWithDelay={withDelay}
            isActive={card.isActiveOnTimeline}
            shouldHighlight={pivotCardId === card.id}/>
        </li>);
      }));
    }
    renderTimeline(false)
  }, [cards, isFirstTimeShowingTimelineOnRound, pivotCardId])

  function renderNotificationsComponent(showingNotifications: boolean) {
    return (
      <TableNotifications
        hidden={!showingNotifications}
        currentPlayer={currentPlayer}
        previousPlayer={previousPlayer}
        playerScored={playerScored}
        playerCard={playerCard}
        showingAppliedTimeoutPenalty={showingAppliedTimeoutPenalty}
        hideAppliedTimeoutPenaltyNotification={hideAppliedTimeoutPenaltyNotification}
        awaitingPlayerReconnection={awaitingPlayerReconnection}
        reconnectTimeLimit={reconnectTimeLimit}
        showingPlayResult={showingPlayResult}
      />)
  }

  function renderTimeline(showingNotifications: boolean) {
    if(showingNotifications) return <></>;
    var component: JSX.Element[] = [];

    if (hasPreviousWindow) {
      if (isPlayableTable) {
        component.push(
          <li key="previous-key">
          <IconButton color='primary' onClick={() => onShowPreviousWindow()}>
            <ArrowBackIosIcon fontSize="large"/>
          </IconButton>
          </li>
        );
      } else {
        component.push(<ArrowBackIosIcon fontSize="large"/>);
      }
    }

    cardsComponent.forEach(card => component.push(card));

    if (hasNextWindow) {
      if (isPlayableTable) {
        component.push(
          <li key="forward-key">
          <IconButton color='primary' onClick={() => onShowNextWindow()}>
            <ArrowForwardIosIcon fontSize="large"/>
          </IconButton>
          </li>
        );
      } else {
        component.push(<ArrowForwardIosIcon fontSize="large"/>);
      }
    }

    return component;
  }

  return (
    <div className= "Timeline">
      {renderNotificationsComponent(showingNotifications)}
      {renderTimeline(showingNotifications)}
    </div>
  );
}

export default Timeline;