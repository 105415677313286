import { useEffect } from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { TypeAnimation } from 'react-type-animation';

import { 
  AUTO_HIDE_DURATION_FOR_APPLIED_TIMEOUT_NOTIFICATION_IN_MS,
  DEFAULT_WAIT_TIME_TO_SHOW_NEXT_WORD_IN_MS
} from "../constants";
import { CardModel } from "../models/CardModel";
import { PlayerModel } from "../models/PlayerModel";
import { RevealedCard } from "./RevealedCard";
import { useTranslation } from "react-i18next";

export function TableNotifications({
  showingAppliedTimeoutPenalty, awaitingPlayerReconnection, playerCard, previousPlayer,
  playerScored, currentPlayer, reconnectTimeLimit, hideAppliedTimeoutPenaltyNotification,
  hidden, showingPlayResult
} : {
  showingAppliedTimeoutPenalty: boolean, awaitingPlayerReconnection: boolean,
  playerCard: CardModel | undefined, previousPlayer: PlayerModel | undefined,
  playerScored: boolean, currentPlayer: PlayerModel | undefined, reconnectTimeLimit: number,
  hideAppliedTimeoutPenaltyNotification: any,
  hidden: boolean, showingPlayResult: boolean
}) {
  const eventBus = document;
  const { t } = useTranslation();
  const userLanguage = navigator.language.split('-')[0];

  useEffect(() => {
    eventBus.addEventListener("custom:showAppliedTimeoutPenaltyNotification", () => {
      setTimeout(hideAppliedTimeoutPenaltyNotification, AUTO_HIDE_DURATION_FOR_APPLIED_TIMEOUT_NOTIFICATION_IN_MS)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function renderPlayResultComponentIfConditionsAreMet() {
    if (
      showingAppliedTimeoutPenalty ||
      awaitingPlayerReconnection || !showingPlayResult) return <></>
    return (
      <div style={{
        display: 'flex',
  
        justifyContent: 'center',
        alignItems: 'center',
        gap: '5vw',
  
        width: '35vw',
        height: '30vh',
      }}>
      <RevealedCard
        description={playerCard?.description}
        year={playerCard?.year}
        nameEN={playerCard?.nameEN}
        namePT={playerCard?.namePT}
        cardId={playerCard?.id}
        isActive={true}
      />
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        gap: '2vh'
      }}>
      <Card sx={{ 
        background: 'rgba(0, 0, 0, 0.55)',
        fontSize: '2vmin',
        color: 'white',
        borderRadius: '20px'}}>
        <CardContent>
        <Typography
          sx={{
            color: playerScored ? 'rgb(170, 255, 170)' : 'rgb(255, 190, 190)',
            textDecoration: playerScored ? 'none' : 'underline'
          }}
          variant='h6'>
          <strong>
          {previousPlayer?.name}
          {playerScored ? ` ${t('tableNotifications.scored')}!` : ` ${t('tableNotifications.missed')}`}
          </strong>
        </Typography>
        </CardContent>
      </Card>
      {playerScored && (
      <Card sx={{ 
        background: 'rgba(0, 0, 0, 0.55)',
        fontSize: '2vmin',
        color: 'white',
        borderRadius: '20px'}}>
        <CardContent>
        <Typography 
          sx={{color: 'rgb(170, 255, 170)'}}
          variant='h6'
        >
          {userLanguage === 'pt' ? playerCard?.namePT : playerCard?.nameEN} {t('tableNotifications.wasAddedToTheTimeline')}
        </Typography>
        </CardContent>
        </Card>
      )}
      </div>
      </div>
      )
  }

  function renderAwaitingPlayerReconnectionComponentIfConditionsAreMet() {
    if (!awaitingPlayerReconnection) return <></>
    return (
      <Card sx={{ 
        background: 'rgba(0, 0, 0, 0.55)',
        fontSize: '2vmin',
        color: 'white',
        borderRadius: '20px'}}>
        <CardContent>
        <TypeAnimation 
          sequence={[
            `${t('tableNotifications.awaiting')} ${currentPlayer?.name} ${t('tableNotifications.reconnectToTable')}`,
            DEFAULT_WAIT_TIME_TO_SHOW_NEXT_WORD_IN_MS,
          ]}
          style={{fontWeight: 'bold', fontSize: '2rem'}}
          repeat={0}
          cursor={false}
          speed={70}
        />
        <br/>
        {t('tableNotifications.timelimit')}: {reconnectTimeLimit} {t('tableNotifications.seconds')}
        </CardContent>
      </Card>
      )
  }

  function renderAppliedTimeoutPenaltyNotificationIfConditionsAreMet() {
    if (!showingAppliedTimeoutPenalty) return <></>
    return (
      <Card sx={{ 
        background: 'rgba(0, 0, 0, 0.55)',
        fontSize: '2vmin',
        color: 'white',
        borderRadius: '20px'}}>
        <CardContent>
        <TypeAnimation 
          sequence={[
            `${previousPlayer?.name} ${t('tableNotifications.timelimitPenaltyApplied')}`,
            DEFAULT_WAIT_TIME_TO_SHOW_NEXT_WORD_IN_MS,
          ]}
          style={{fontWeight: 'bold', fontSize: '2rem'}}
          repeat={0}
          cursor={false}
          speed={60}
        />
        </CardContent>
      </Card>
    )
  }

  return (
    <div style={{display: hidden ? 'none' : 'initial'}}>
    {renderPlayResultComponentIfConditionsAreMet()}
    {renderAwaitingPlayerReconnectionComponentIfConditionsAreMet()}
    {renderAppliedTimeoutPenaltyNotificationIfConditionsAreMet()}
    </div>
  );
}