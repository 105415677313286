import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Button from '@mui/material/Button';
import { Card, CardContent, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

import "../style/Home.css";
import { BACKEND_BASE_URL } from '../constants';
import i18n from '../i18n';

function Home() {
  const [connectToTableInfo, setConnectToTableInfo] = useState({tableExists: false, userId: "", tableId: "", shouldRedirect: false});

  const [loginOpen, setLoginOpen] = useState(false);
  const [quickGameOpen, setQuickGameOpen] = useState(false);
  const [createOnlineRoomOpen, setCreateOnlineRoomOpen] = useState(false);
  const [partyModeOpen, setPartyModeOpen] = useState(false);
  const [onlineModeOpen, setOnlineModeOpen] = useState(false);

  var errorAlert = <></>;
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  const handleOpenLogin = () => setLoginOpen(!loginOpen);
  const handleOpenCreateOnlineRoom = () => setCreateOnlineRoomOpen(!createOnlineRoomOpen);
  const handleOpenQuickGame = () => setQuickGameOpen(!quickGameOpen);

  const handleOpenPartyMode = () => {
    setPartyModeOpen(!partyModeOpen);
  }

  const handleOpenOnlineMode = () => {
    setOnlineModeOpen(!onlineModeOpen);
  }

  const handleChangeLanguage = () => {
    // deliberately handles only pt and en
    if (i18n.language === 'pt') i18n.changeLanguage("en")
    else i18n.changeLanguage("pt")
  }

  const [noOfActiveTables, setNoOfActiveTables] = useState();
  const [noOfActivePlayers, setNoOfActivePlayers] = useState();

  useEffect(() => {
    axios.get(BACKEND_BASE_URL + '/tables')
      .then(response => setNoOfActiveTables(response.data))
      .catch(error => console.error(error));

    axios.get(BACKEND_BASE_URL + '/players')
      .then(response => setNoOfActivePlayers(response.data))
      .catch(error => console.error(error));
  }, [])

  useEffect(() => {
    if (!connectToTableInfo.shouldRedirect) return;
    if (connectToTableInfo.tableExists) {
      navigate(
        `/play/table/${connectToTableInfo.tableId}/player/${connectToTableInfo.userId}`,
        {state: {type : 'Redirect'}});
    } else {
      setConnectToTableInfo(prevState => ({...prevState, shouldRedirect: false}))
      navigate(
        "/",
        {state: {error_message : `Sala ${connectToTableInfo.tableId} não encontrada.`, type : 'Erro'}})
    }
  }, [navigate, connectToTableInfo, loginOpen])

  const handlePlayerConnectToTable = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const tableId = data.get('idRoom')?.toString().toUpperCase() ?? "";
    const userId = data.get('userId')?.toString() ?? "";

    axios.get(`${BACKEND_BASE_URL}/tables/${tableId}/exists`)
    .then((response) =>{
      setConnectToTableInfo({
        tableExists: JSON.parse(response.data), userId: userId, tableId: tableId, shouldRedirect: true})
    });
  }

  const handleConnectFromOnlineMode = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const tableId = data.get('idRoom')?.toString().toUpperCase() ?? "";
    const userId = data.get('userId')?.toString() ?? "";
  
    if (createOnlineRoomOpen) {
      navigate(
        `/game/online/main/${userId}`,
        {state: {type : 'Redirect'}});
    }
    if (loginOpen) {
      navigate(
        `/game/online/table/${tableId}/player/${userId}`,
        {state: {type : 'Redirect'}});
    }
    if (quickGameOpen) {
      navigate(
        `/game/online/quick/player/${userId}`,
        {state: {type : 'Redirect'}});
    }
  }

  if (location.state && location.state.type === "Erro") {
    errorAlert = <Collapse in={location.state || false}>
      <Alert severity="error"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              navigate('/');
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2 }}
      >
        {location.state.error_message}
      </Alert>
    </Collapse>
  }

  return (
    <div className="Home ColumnFlexWithCenteredItems">
      <h2 className="Options">
        <div id='titleText'/>
        <Button
          sx={{display: (partyModeOpen || loginOpen || createOnlineRoomOpen || quickGameOpen) ? 'none' : 'default'}}
          id='createRoom' variant='outlined'
          onClick={handleOpenOnlineMode}
        >
          <Typography fontSize={'2em'}>
            {t('home.onlineMode')}
          </Typography>
        </Button>
        <Collapse in={!partyModeOpen && onlineModeOpen}>
          <Card sx={{ 
              background: 'rgba(0, 0, 0, 0.55)',
              color: 'white',
              borderRadius: '20px',
              marginTop: '-0.2em',
              marginBottom: '1em'
            }}>
            <CardContent>
            <Typography fontSize={'0.6em'}>
            {t('home.onlineModeExplanation')}
            </Typography>
            </CardContent>
          </Card>
          <Button 
            sx={{
              display: partyModeOpen ? 'none' : 'default',
              opacity: loginOpen || createOnlineRoomOpen ? '0.7' : '1'
            }}
            id='joinRoom'
            variant='outlined'
            disabled={loginOpen || createOnlineRoomOpen}
            onClick={handleOpenQuickGame}>
            <Typography fontSize={'2em'}>
              {t('home.quickGame')}
            </Typography>
          </Button>
          <Button 
            sx={{
              display: partyModeOpen ? 'none' : 'default',
              opacity: createOnlineRoomOpen || quickGameOpen ? '0.7' : '1',
              marginLeft: '5vw'
            }}
            id='joinRoom'
            disabled={createOnlineRoomOpen || quickGameOpen}
            variant='outlined'
            onClick={handleOpenLogin}>
            <Typography fontSize={'2em'}>
              {t('home.joinRoom')}
            </Typography>
          </Button>
          <Button 
            sx={{
              display: partyModeOpen ? 'none' : 'default',
              marginLeft: '5vw',
              opacity: loginOpen || quickGameOpen ? '0.7' : '1'
            }}
            id='joinRoom'
            variant='outlined'
            disabled={loginOpen || quickGameOpen}
            onClick={handleOpenCreateOnlineRoom}>
            <Typography fontSize={'2em'}>
              {t('home.createRoom')}
            </Typography>
          </Button>
          <Collapse in={loginOpen || createOnlineRoomOpen || quickGameOpen}>
            <Box 
              component="form"
              onSubmit={handleConnectFromOnlineMode}
              noValidate
              autoComplete='off'
              sx={{ mt: 1 }}
            >
              <TextField
                id="userId"
                name="userId"
                label={t('home.typeName')}
                variant="filled"
                inputProps={{ maxLength: 12 }}
                sx={{
                  "& .MuiInputLabel-root": {color: 'white'},
                  "& .MuiFilledInput-input": {border: '1px solid white', borderRadius: 1.5},
                  input: {color: 'white'},
                }}/>
              {/* TODO: remove these <br> tags. Use css to space things correctly. */}
              <br/>
              {loginOpen && (
              <TextField
                id="idRoom"
                name="idRoom"
                label={t('home.typeRoomId')}
                variant="filled"
                sx={{
                  "& .MuiInputLabel-root": {color: 'white'},
                  "& .MuiFilledInput-input": {border: '1px solid white', borderRadius: 1.5},
                  input: {color: 'white'},
                  marginTop: '2vh',
                  marginBottom: '3vh'
                }}/>
              )}
              <br/>
              <Button id='connectToRoom' type="submit" variant="outlined">
                <Typography fontSize={'1.5em'}>
                {t('home.confirm')}
                </Typography>
              </Button>
            </Box>
          </Collapse>
        </Collapse>

        <Button 
          sx={{display: (onlineModeOpen || loginOpen) ? 'none' : 'default'}}
          id='joinRoom' variant='outlined' 
          onClick={handleOpenPartyMode}
        >
          <Typography fontSize={'2em'}>
            {t('home.partyMode')}
          </Typography>
        </Button>
        <Collapse in={partyModeOpen && !onlineModeOpen}>
          <Card sx={{ 
            background: 'rgba(0, 0, 0, 0.55)',
            color: 'white',
            borderRadius: '20px',
            marginTop: '-0.2em',
            marginBottom: '1em'
          }}>
            <CardContent>
            <Typography fontSize={'0.6em'}>
              {t('home.partyModeExplanation')}
            </Typography>
            </CardContent>
          </Card>
          <Button 
            sx={{display: onlineModeOpen ? 'none' : 'default'}}
            id='joinRoom' variant='outlined' onClick={handleOpenLogin}>
            <Typography fontSize={'2em'}>
              {t('home.joinRoom')}
            </Typography>
          </Button>
          <Button 
            sx={{
              display: onlineModeOpen ? 'none' : 'default',
              marginLeft: '5vw',
              opacity: loginOpen ? '0.7' : '1'
            }}
            id='joinRoom'
            variant='outlined'
            disabled={loginOpen}
            href='/game/party'>
            <Typography fontSize={'2em'}>
              {t('home.createRoom')}
            </Typography>
          </Button>
          <Collapse in={loginOpen}>
            <Box 
              component="form"
              onSubmit={handlePlayerConnectToTable}
              noValidate
              autoComplete='off'
            >
              <TextField
                id="userId"
                name="userId"
                label={t('home.typeName')}
                variant="filled"
                inputProps={{ maxLength: 12 }}
                sx={{
                  "& .MuiInputLabel-root": {color: 'white'},
                  "& .MuiFilledInput-input": {border: '1px solid white', borderRadius: 1.5},
                  input: {color: 'white'},
                }}/>
              {/* TODO: remove these <br> tags. Use css to space things correctly. */}
              <br/>
              <TextField
                id="idRoom"
                name="idRoom"
                label={t('home.typeRoomId')}
                variant="filled"
                sx={{
                  "& .MuiInputLabel-root": {color: 'white'},
                  "& .MuiFilledInput-input": {border: '1px solid white', borderRadius: 1.5},
                  input: {color: 'white'},
                  marginTop: '2vh',
                  marginBottom: '3vh'
                }}/>
              <br/>
              <Button id='connectToRoom' type="submit" variant="outlined">
                <Typography fontSize={'1.5em'}>
                {t('home.confirm')}
                </Typography>
              </Button>
            </Box>
          </Collapse>
        </Collapse>

        <Button 
          sx={{
          }}
          id='changeLanguage'
          variant='outlined'
          onClick={handleChangeLanguage}>
          <Typography fontSize={'1.1em'}>
            {t('home.changeLanguage')}
          </Typography>
        </Button>

        <Typography fontSize={'0.5em'}>
          {t('home.noOfActivePlayersMessagePt1')} {noOfActivePlayers} {t('home.noOfActivePlayersMessagePt2')} {noOfActiveTables} {t('home.noOfActivePlayersMessagePt3')}
        </Typography>
        {errorAlert}
      </h2>
    </div>
  );
}

export default Home;
