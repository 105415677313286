import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next';

export default function PodiumStep(props: any) {
  const { t } = useTranslation();

  const proportionOffsetBasedOnRank = 1 / props.rank
  const proportionOffsetBasedOnScore = props.winner.finalRound / props.maxScore
  
  const maxRGain = 255 - 131
  const maxGGain = 255 - 69
  const maxBGain = 255 - 65

  const mapRankToText = (rank: number) => {
    switch(rank) {
      case 1: return '🏆'
      case 2: return '🥈'
      case 3: return '🥉'
      default: return rank + 'o'
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        placeContent: 'center'
      }}
    >
      <motion.div
        style={{
          alignSelf: 'start',
          marginBottom: '.25rem'
        }}
        initial="hidden"
        animate="visible"
        variants={{
          visible: {
            opacity: 1,
            transition: {
              delay: 1 + (proportionOffsetBasedOnRank + 2),
              duration: 0.75
            }
          },
          hidden: { opacity: 0 }
        }}
      >
        {/* TODO: add google profile image? */}
        <span style={{ color: 'white', fontSize: '2.7vmin'}}>
          <strong>{mapRankToText(props.winner.rank)}{props.winner.name}</strong>
        </span>
      </motion.div>

      <motion.div
        style={{
          height: '4vh',
          placeContent: 'center',
          display: 'flex',
          borderBottomRightRadius: '.5rem',
          borderTopRightRadius: '.5rem',
          borderColor: 'rgba(190,24,93,1)',
          backgroundColor: `rgb(
            calc(131 + ${maxRGain * (proportionOffsetBasedOnRank)}),
            calc(69 + ${maxGGain * (proportionOffsetBasedOnRank)}),
            calc(65 + ${maxBGain * (proportionOffsetBasedOnRank)})`
        }}
        initial="hidden"
        animate="visible"
        variants={{
          visible: {
            width: `calc(49vw * ${proportionOffsetBasedOnScore})`,
            opacity: 1,
            transition: {
              delay: 3 * proportionOffsetBasedOnRank,
              duration: 2,
              ease: 'backInOut'
            }
          },
          hidden: { opacity: 0, width: 0 }
        }}
      >
        <span style={{ color: 'black', alignSelf: 'center', fontSize: '2.2vmin' }}>
          <strong>{t('podiumStep.difficulty')} {props.winner.finalRound}</strong>
        </span>
      </motion.div>
    </div>
  )
}
