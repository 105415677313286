import { Backdrop, CircularProgress, Typography, Button, Card, Tooltip } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import { useState, useEffect } from "react";
import useWebSocket from "react-use-websocket";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import PlayerList from "./PlayerList";
import { EventNames } from "../models/EventNames";
import { getUrlForTableWsConnection, isOnlineMode } from "../utils";

export function WaitingRoom(props: any) {
  const { playerId, tableId } = useParams();

  const urlForTableConnection = getUrlForTableWsConnection(tableId, playerId);
  const { lastMessage } = useWebSocket(urlForTableConnection, {share:true});

  const [tableIdFromEvent, setTableIdFromEvent] = useState<string | null>(null);
  const [playersList, setPlayersList] = useState<string[]>([]);

  const { t } = useTranslation();
  const _isOnlineMode = isOnlineMode();
  const shouldBlockStartButtonForNonHost = !props.isMainView && _isOnlineMode;

  useEffect(() =>{
    if (lastMessage !== null) {
      const response = JSON.parse(lastMessage.data);
      const eventName = response.type;

      switch(eventName) {
        case EventNames.connectedAdditionalView: {
          const _tableId = response.data.tableId;
          setTableIdFromEvent(_tableId);
          break;
        }
        case EventNames.updatePlayersOnWaitingRoom: {
          setPlayersList(response.data.players || []);
          break;
        }
      }
    }
  }, [lastMessage, setPlayersList])

  const qrCodeUrl = `saidera.com.br/play/table/${props.roomId}`
  const tooltipText = shouldBlockStartButtonForNonHost ? t('waitingRoom.onlyHostMayStart') :
    (playersList.length > 0) ? t('waitingRoom.startGame') : t('waitingRoom.unsufficientNoOfPlayers')
  return (
      <><>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={props.serverConnectionOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop><div className='ListOfPlayersInRoom ColumnFlexWithCenteredItems'>
        <div>
          <Typography variant='h4'>
            <strong>{t('waitingRoom.room')} {props.roomId || tableId || tableIdFromEvent}</strong>
          </Typography>
          <Typography variant='h6' sx={{ mt: 1 }}>
            {_isOnlineMode ? t('waitingRoom.helperTextForOnlineMode') : t('waitingRoom.helperText')}
          </Typography>
        </div>
        <div className="RowFlexWithCenteredItems Gap7vw">
          {!_isOnlineMode &&
          <Card
            sx={{
              background: 'rgba(155, 104, 91, 0.55)',
              color: 'white',
              padding: '9px',
              borderRadius: '20px'
            }}>
            <QRCodeSVG value={qrCodeUrl} size={240} />
            <Typography variant='subtitle1' sx={{ mt: 1, padding: 1 }}>
              {t('waitingRoom.qrCodeHelperText')}
            </Typography>
          </Card>
          }
          <PlayerList players={playersList} />
        </div>
        <Tooltip title={tooltipText}>
          <div>
            <Button
              id="startGame"
              disabled={playersList.length === 0 || shouldBlockStartButtonForNonHost }
              variant="outlined"
              onClick={props.handleStartGame}
            >
              <Typography fontSize={'2em'}>
                {t('waitingRoom.startGameShort')}
              </Typography>
            </Button>
          </div>
        </Tooltip>
      </div></>
      </>
  )
}
