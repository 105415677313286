import { useEffect, useState } from 'react';
import { IconButton, Typography } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { Label, Pie, PieChart, ResponsiveContainer } from 'recharts';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useNavigate, useParams } from 'react-router-dom'
import useSound from 'use-sound';
import { useTranslation } from 'react-i18next';
import useWakeLock from "react-use-wake-lock";
import ReactJoyride from 'react-joyride';

import "../style/Home.css";

import { CardModel, Cards } from '../models/CardModel';
import { PlayerModel, Players } from '../models/PlayerModel';
import { EventNames } from '../models/EventNames';

import Timeline from '../components/Timeline';
import { ActivePlayers } from '../components/ActivePlayers';
import Podium from '../components/Podium';
import { ShowcasedPlayerCard } from '../components/ShowcasedPlayerCard';
import { WaitingRoom } from '../components/WaitingRoom';

import {
  BACKEND_WS_URL,
  GAME_STATUS,
  INTERVAL_BETWEEN_RECONNECT_TRIES_IN_MILLISECONDS,
  MAX_NUMBER_OF_RECONNECT_ATTEMPTS
} from '../constants';

import { getUrlForTableWsConnection, isAdditionalView, isOnlineMode } from '../utils';

import playerOutSound from '../assets/soundEffects/playerOut.mp3'
import rightGuessSound from '../assets/soundEffects/rightGuess.mp3'
import wrongGuessSound from '../assets/soundEffects/wrongGuess.wav'
import startNewRoundSound from '../assets/soundEffects/startNewRound.wav'
import cardPlacedSound from '../assets/soundEffects/cardPlaced.wav'
import intermediateRightGuessSound from '../assets/soundEffects/intermediateRightGuess.wav'

function Table() {
  const eventBus = document;
  const { playerId, tableId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { request } = 
    useWakeLock({
      onError(e, type) {
        console.error("Wake Lock Error: REQUEST: ", e, type);
      },
      onLock(lock) {
        console.info("Wake Lock Acquired: ", lock);
      },
      onRelease(lock) {
        console.info("Wake Lock Released: ", lock);
      },
    });

  const tutorialSteps = [
    {
      target: '.Timeline',
      content: t('table.tutorialStep1'),
      locale: { 
        skip: t('table.skip'),
        next: t('table.next'),
        back: t('table.back')
      },
      disableBeacon: true,
    },
    {
      target: '.BHRight',
      content: t('table.tutorialStep2'),
      locale: { 
        skip: t('table.skip'),
        next: t('table.next'),
        back: t('table.back')
      },
    },
    {
      target: '.BHLeft',
      content: t('table.tutorialStep3'),
      locale: { 
        skip: t('table.skip'),
        next: t('table.next'),
        back: t('table.back')
      },
    },
    {
      target: '.BHLeft',
      content: t('table.tutorialStep4'),
      locale: { 
        skip: t('table.skip'),
        next: t('table.next'),
        back: t('table.back')
      },
    },
    {
      target: '#tableRoomId',
      content: t('table.tutorialStep5'),
      locale: { 
        skip: t('table.skip'),
        next: t('table.next'),
        back: t('table.back')
      },
    },
    {
      target: '.BHRight',
      content: t('table.tutorialStep6'),
      locale: { 
        skip: t('table.skip'),
        next: t('table.next'),
        back: t('table.back'),
        last: t('table.last')
      },
    },
  ];

  const [playWrongGuessSound] = useSound(wrongGuessSound);
  const [playRightGuessSound] = useSound(rightGuessSound);
  const [playStartNewRoundSound] = useSound(startNewRoundSound);
  const [playPlayerOutSound] = useSound(playerOutSound, { volume: 0.1 });
  const [playCardPlacedSound] = useSound(cardPlacedSound);
  const [playIntermediateRightGuessSound] = useSound(intermediateRightGuessSound);

  const [open, setOpen] = useState(true);
  const [ranOutOfCards, setRanOutOfCards] = useState(false);
  const [isInMiddleOfTurn, setIsInMiddleOfTurn] = useState(false);

  const [gameStatus, setGameStatus] = useState(GAME_STATUS.NotStarted);
  const [roomId, setRoomId] = useState<null | string>(null);
  const [currentRound, setCurrentRound] = useState(0);
  const [currentPlayer, setCurrentPlayer] = useState<PlayerModel>();
  const [previousPlayer, setPreviousPlayer] = useState<PlayerModel>();

  const [playerCard, setPlayerCard] = useState<CardModel | undefined>();
  const [pivotCard, setPivotCard] = useState<CardModel | undefined>();

  const [timeLimitForGuessInSeconds, setTimeLimitForGuessInSeconds] = useState(0);
  const [timeLeftToGuess, setTimeLeftToGuess] = useState(0);

  const [cardsOfTimeline, setCardsOfTimeline] = useState<Cards>([]);
  const [isFirstTimeShowingTimelineOnRound, setIsFirstTimeShowingTimelineOnRound] = useState(true);

  const [activePlayers, setActivePlayers] = useState<Players>([]);
  const [scoreboard, setScoreboard] = useState<string[]>([]);

  const [currentWindowIndex, setCurrentWindowIndex] = useState(0);
  const [numberOfTimelineWindows, setNumberOfTimelineWindows] = useState(1);

  const [shouldRedirectHome, setShouldRedirectHome] = useState(false);
  const [isMainViewFromEvt, setIsMainViewFromEvt] = useState(false);

  const [shouldShowTutorial, setShouldShowTutorial] = useState(false);

  const _isOnlineMode = isOnlineMode();
  const _isAdditionalView = isAdditionalView();

  const urlForTableConnection = getUrlForTableWsConnection(tableId, playerId);
  const { sendJsonMessage, lastMessage, readyState } =
    useWebSocket(urlForTableConnection, {
      share: true,
      shouldReconnect: () => gameStatus !== GAME_STATUS.Finished,
      retryOnError: true,
      reconnectAttempts: MAX_NUMBER_OF_RECONNECT_ATTEMPTS,
      reconnectInterval: INTERVAL_BETWEEN_RECONNECT_TRIES_IN_MILLISECONDS,
      onReconnectStop: () => setShouldRedirectHome(true),
      onClose: (e) => {
        console.log('Websocket connection closed!', e)
      },
      onError: (e) => {
        console.log('Error on Websocket connection!', e)
        setShouldRedirectHome(true)
      }
    });

  const [timeToPlay, setTimeToPlay] = useState(false);
  const [playerSocketUrl, setPlayerSocketUrl] = useState<any>(new Promise(() => {}));

  const isPlayableTable = () => playerSocketUrl !== "";
  const { sendJsonMessage: playerSendJsonMessage, lastMessage: playerLastMessage } = 
    useWebSocket(playerSocketUrl, {
      share: true,
      shouldReconnect: () => gameStatus !== GAME_STATUS.Finished,
      retryOnError: isPlayableTable(),
      reconnectAttempts: MAX_NUMBER_OF_RECONNECT_ATTEMPTS,
      reconnectInterval: INTERVAL_BETWEEN_RECONNECT_TRIES_IN_MILLISECONDS,
      onReconnectStop: () => setShouldRedirectHome(true),
      onError: () => setShouldRedirectHome(true)
    });

  useEffect(() => {
    eventBus.addEventListener("custom:playWrongGuessSound", () => {
      playWrongGuessSound();
    })
    eventBus.addEventListener("custom:playRightGuessSound", () => {
      playRightGuessSound();
    })
    eventBus.addEventListener("custom:playStartNewRoundSound", () => {
      playStartNewRoundSound();
    })
    eventBus.addEventListener("custom:playPlayerOutSound", () => {
      playPlayerOutSound();
    })
    eventBus.addEventListener("custom:playCardPlacedSound", () => {
      playCardPlacedSound();
    })
    eventBus.addEventListener("custom:playIntermediateRightGuessSound", () => {
      playIntermediateRightGuessSound();
    })
  }, [eventBus, playCardPlacedSound, playIntermediateRightGuessSound, playPlayerOutSound, playRightGuessSound, playStartNewRoundSound, playWrongGuessSound])

  // Handles events for players on Online mode.
  useEffect(() => {
    const sendPong = () => {
      playerSendJsonMessage({type: 'pong', data: {}});
    }

    const processNonAck = (evt: any) => {    
      let evtName = evt.type
      if(evtName !== EventNames.ping) console.log(evt)

      switch(evtName) {
        case "ping": {
          sendPong()
          break;
        }
        case EventNames.requestToPlay: {
          const isFirstPlay = evt.data.isFirstPlay;
          setTimeToPlay(true);
          if (isFirstPlay === 'true') setShouldShowTutorial(true);
          console.log('opa received', evt.data)
          break;
        }
        case EventNames.playerOut: {
          break;
        }
        case EventNames.appliedTimeoutPenalty: {
          setTimeToPlay(false)
          break;
        }
      }
    }

    const processAck = (evt: any) => {
      let evtName = evt.data.refersTo
      switch(evtName) {
        case EventNames.playerChoice: {
          setTimeToPlay(false);
          setShouldShowTutorial(false);
          break;
        }
      }
    }

    const processEvent = (eventJson: MessageEvent<any>) => {
      let event = JSON.parse(eventJson.data)
      let evtType = event.type 
      switch(evtType) {
        case EventNames.ack: {
          processAck(event)
          break;
        }
        default: {
          processNonAck(event)
          break;
        }
      }
    }
    
    if (playerLastMessage !== null) {
      processEvent(playerLastMessage)
    }
  }, [playerLastMessage, playerSendJsonMessage])

  const onPlayerGuess = (beforePivotCard: boolean) => {
    playerSendJsonMessage({type: 'playerChoice', data: {'beforePivotCard': beforePivotCard.toString()}});
  }

  useEffect(() => {
    const redirectHomeWithError = (errorMessage: string) => {
      navigate("/", {state: {error_message : errorMessage, type : 'Erro'}})
    }
    if(shouldRedirectHome) {
      redirectHomeWithError( t('errorMessages.errorConnectingToServer') )
    }
  }, [navigate, shouldRedirectHome, t])

  const handleStartGame = () => {
    sendJsonMessage({type: 'startGame', data: {}});
    setGameStatus(GAME_STATUS.Running); // TODO: check if backend acked this
    
    // for screen wake lock
    request();
  }

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      setOpen(false);
    }
  }, [readyState]);

  // Handles events for the table on all modes.
  useEffect(() => {
    const sendPong = () => {
      sendJsonMessage({type: 'pong', data: {}});
    }

    const parseAndSetActivePlayers = (players: string[]) => {
      if (players) {
        const parsedPlayers: Players = []
        players.forEach((player: string) => {
          parsedPlayers.push(JSON.parse(player))
        });
        setActivePlayers(parsedPlayers)
      }
    }

    const showPartialTimeline = (
      cards: any, _cardPoolSize: any, _noOfTimelineWindows: any, windowIndex: any,
      firstTimeShowingTimelineOnRound: any) => {
      const sendAckToEvt = () => {
        sendJsonMessage({type: 'ack', data: {"refersTo": EventNames.showPartialTimeline}})
      }

      setCardsOfTimeline(cards);
      setIsFirstTimeShowingTimelineOnRound(firstTimeShowingTimelineOnRound);
      setNumberOfTimelineWindows(_noOfTimelineWindows);
      setCurrentWindowIndex(windowIndex);
      sendAckToEvt();
    }

    const processAck = (evt: any) => {
      let evtName = evt.data.refersTo;
      switch(evtName) {
        case EventNames.createNewTable: {
          const _tableId = evt.data.response.tableId;
          setRoomId(_tableId);

          if(_isOnlineMode) {
            setPlayerSocketUrl(Promise.resolve(BACKEND_WS_URL + '/tables/' + _tableId + '/connect/' + playerId));
            setIsMainViewFromEvt(true);
          }
          break;
        }
      }
    }
    
    const processNonAck = (evt: any) => {
      let evtName = evt.type
      if(evtName !== EventNames.ping &&
        evtName !== EventNames.updatePlayersOnWaitingRoom &&
        evtName !== EventNames.awaitingPlayerReconnection &&
        evtName !== EventNames.awaitingPlayerGuess) console.log('Table:', evt)
  
      switch(evtName) {
        case EventNames.ping: {
          sendPong()
          break;
        }
        case EventNames.showPartialTimeline: {
          const { 
            noOfWindows: _noOfTimelineWindows,
            cardPoolSize: _cardPoolSize,
            cards, windowIndex, firstTimeShowingTimelineOnRound
          } = evt.data

          const parsedCards: Cards = []
          cards.forEach((card: string) => {
            parsedCards.push(JSON.parse(card))
          });

          showPartialTimeline(
            parsedCards, _cardPoolSize, _noOfTimelineWindows, windowIndex,
            firstTimeShowingTimelineOnRound)
          break;
        }
        case EventNames.showPlayerCard: {
          let playerCard = JSON.parse(evt.data.playerCard);
          setPlayerCard(playerCard);

          let pivotCard = JSON.parse(evt.data.pivotCard);
          setPivotCard(pivotCard);

          let player = JSON.parse(evt.data.player);
          setCurrentPlayer(player);

          setIsInMiddleOfTurn(true);
          break;
        }
        case EventNames.startOfRound: {
          setGameStatus(GAME_STATUS.Running);
          parseAndSetActivePlayers(evt.data.activePlayers);
          setCurrentRound(evt.data.roundNumber);
          eventBus.dispatchEvent(new CustomEvent("custom:playStartNewRoundSound"));
          break;
        }
        case EventNames.updateActivePlayers: {
          parseAndSetActivePlayers(evt.data.activePlayers);
          setCurrentRound(evt.data.round);
          break;
        }
        case EventNames.gameFinished: {
          setGameStatus(GAME_STATUS.Finished)
          setScoreboard(evt.data.scoreboard)
          setRanOutOfCards(evt.data.ranOutOfCards)
          break;
        }
        case EventNames.endOfPlayerTurn: {
          setIsInMiddleOfTurn(false);
          parseAndSetActivePlayers(evt.data.activePlayers)
          var scored = false;
          if (evt.data.scored === 'true') scored = true;

          var outOfGame = false;
          if (evt.data.outOfGame === 'true') outOfGame = true;

          if (scored) { eventBus.dispatchEvent(new CustomEvent("custom:playRightGuessSound")); }
          else { 
            if(outOfGame) { eventBus.dispatchEvent(new CustomEvent("custom:playPlayerOutSound")); }
            else eventBus.dispatchEvent(new CustomEvent("custom:playWrongGuessSound"));
          }

          let player = JSON.parse(evt.data.player);
          setPreviousPlayer(player);

          setPlayerCard(undefined);
          setPivotCard(undefined);
          setTimeLeftToGuess(0);
          break;
        }
        case EventNames.awaitingPlayerGuess: {
          setTimeLimitForGuessInSeconds(evt.data.totalTimeToGuess);
          setTimeLeftToGuess(evt.data.timeLeftToGuessInSeconds);
          break;
        }
        case EventNames.appliedTimeoutPenalty: {
          eventBus.dispatchEvent(new CustomEvent("custom:playWrongGuessSound"));

          let player = JSON.parse(evt.data.player)
          setPreviousPlayer(player)

          break;
        }
        case EventNames.intermediateGuessMade: {
          var wasRightGuess = false;
          if (evt.data.wasRightGuess === 'true') wasRightGuess = true;
          if (wasRightGuess) eventBus.dispatchEvent(new CustomEvent("custom:playIntermediateRightGuessSound"));
          break;
        }
        case EventNames.connectedAdditionalView: {
          const isReconnect = evt.data.isReconnection;
          if(isReconnect) setGameStatus(GAME_STATUS.Running);

          setTimeLimitForGuessInSeconds(evt.data.totalTimeToGuess);
          const _tableId = evt.data.tableId;
          setRoomId(_tableId);

          if(_isOnlineMode) setPlayerSocketUrl(Promise.resolve(BACKEND_WS_URL + '/tables/' + _tableId + '/connect/' + playerId));
          break;
        }
      }
    }

    const processEvent = (eventJson: MessageEvent<any>) => {
      let event = JSON.parse(eventJson.data)
      let evtType = event.type 

      switch(evtType) {
        case "ack": {
          processAck(event);
          break;
        }
        default: {
          processNonAck(event);
          break;
        }
      }
    }
    
    if (lastMessage !== null) {
      processEvent(lastMessage);
    }
  }, [lastMessage, sendJsonMessage, _isOnlineMode, playerId, eventBus])

  return (
    <div className="Table ColumnFlexWithCenteredItems">
      {gameStatus === GAME_STATUS.Running ? (
        <div className='RowsOnHostView'>
        <ReactJoyride 
          steps={tutorialSteps}
          showSkipButton
          showProgress
          continuous
          run={shouldShowTutorial}
          scrollToFirstStep
          styles={{
            options: {
              primaryColor: '#520000'
            }
          }}/>
        <div className='TopHalfOnHostView'>
          <div id='aboveTimeline'>
            <strong>{t('table.timelineTitle')} {currentRound}</strong>
            <div id='logoAboveTimeline'/>
          </div>
          <Timeline 
            cards={cardsOfTimeline} 
            isFirstTimeShowingTimelineOnRound={isFirstTimeShowingTimelineOnRound}
            currentWindowIndex={currentWindowIndex}
            numberOfWindows={numberOfTimelineWindows}
            previousPlayer={previousPlayer}
            currentPlayerCard={playerCard}
            currentPlayer={currentPlayer}
            isPlayableTable={isPlayableTable()}
            playerSocketUrl={playerSocketUrl}
            isTimeToPlay={timeToPlay}/>
        </div>
        <div className='BottomHalfOnHostView'>
        <div className='BHLeft'>
            <div id='topOfBHLeft'>
              <strong>{t('table.playersListTitle')}</strong>
              <p id='tableRoomId'>{t('table.room')} {roomId}</p>
            </div>
            <ActivePlayers 
              players={activePlayers}
              currentPlayerName={currentPlayer?.name}
              ownerOfScreen={playerId}
              isInMiddleOfTurn={isInMiddleOfTurn}/>
          </div>
          <div 
            className='BHRight'
            style={{
              color: 'black',
              opacity: (timeToPlay || !_isOnlineMode) ? 1 : 0.6
            }}>
            <div className='questionAndTimer' style={{color: 'black'}}>
              <strong>{t('table.gameQuestion')}</strong>

              <div style={{width: '100%', height: '15vh'}}>
              <ResponsiveContainer>
              <PieChart>
                <Pie
                  animationBegin={150}
                  animationDuration={350}
                  dataKey={'value'} 
                  data={[
                    { value: timeLimitForGuessInSeconds - timeLeftToGuess, fill: 'white' },
                    { value: timeLeftToGuess, fill: 'rgb(110,0,0)' },
                  ]}
                  innerRadius={35}
                >
                <Label 
                  value={`${timeLeftToGuess}s`}
                  position="center"
                  fill='black'
                  fontSize={'auto'}
                />
                </Pie>
              </PieChart>
              </ResponsiveContainer>
              </div>
            </div>

            <ShowcasedPlayerCard
              key="showcased-1"
              description={playerCard?.description}
              year={playerCard?.year}
              namePT={playerCard?.namePT}
              nameEN={playerCard?.nameEN}
              cardId={playerCard?.id}
              shouldHideYear={true}
              onClick={onPlayerGuess}
              isPivot={false}
              isClickable={timeToPlay}/>

            <ShowcasedPlayerCard
              key="showcased-2"
              description={pivotCard?.description}
              year={pivotCard?.year}
              namePT={pivotCard?.namePT}
              nameEN={pivotCard?.nameEN}
              cardId={pivotCard?.id}
              onClick={onPlayerGuess}
              isPivot={true}
              isClickable={timeToPlay}/>
          </div>
        </div>
        </div>
      ) : 
      gameStatus === GAME_STATUS.Finished ? (
        <>
        <Podium podiumItems={scoreboard}/>
        <IconButton
          color="inherit"
          onClick={() => navigate('/')}
        >
          <HomeIcon sx={{fontSize: '5vh'}} />
        </IconButton>
        </>
      ) : (
        <WaitingRoom
          serverConnectionOpen={open}
          handleStartGame={handleStartGame}
          roomId={roomId}
          isAdditionalView={_isAdditionalView}
          isMainView={isMainViewFromEvt}/>
      )}
    {/* TODO: make this look nice */}
    {ranOutOfCards && (
      <Typography variant='h4'>
        <strong>{t('table.gameCompleted')}</strong>
      </Typography>
    )}
    </div>
  );
}

export default Table;
