import { useEffect, useState } from "react";

import { PlayerItem } from "./PlayerItem";
import { Players } from "../models/PlayerModel";

export function ActivePlayers(
  { players, currentPlayerName, ownerOfScreen, isInMiddleOfTurn }: 
  { players: Players, currentPlayerName: string | undefined, ownerOfScreen: string | undefined, isInMiddleOfTurn: boolean }) {
  const [playerListComponent, setPlayerListComponent] = useState<JSX.Element[]>([]);
  const maxLimitOfVisiblePlayersOnList = 6

  useEffect(() => {
    if (currentPlayerName === undefined) return;

    const currentPlayerIndex = players.findIndex((player) => player?.name === currentPlayerName)
    if (currentPlayerIndex === -1) return;

    const shouldGetFromRightOfCurrentPlayer = (alt: number) => {
      return alt&1
    }

    let playersToBeRendered: Players = []
    playersToBeRendered.push(players[currentPlayerIndex])

    let alt = 0;
    let left = currentPlayerIndex - 1;
    let right = currentPlayerIndex + 1;
    const limitOfVisiblePlayersOnList = Math.min(players.length, maxLimitOfVisiblePlayersOnList);

    while(playersToBeRendered.length < limitOfVisiblePlayersOnList) {
      if(shouldGetFromRightOfCurrentPlayer(alt)) {
        if (right < players.length) {
          playersToBeRendered.push(players[right]);
          right++;
        }
      } else {
        if (left >= 0) {
          playersToBeRendered.reverse()
          playersToBeRendered.push(players[left])
          playersToBeRendered.reverse()
          left--;
        }
      }
      alt ^= 1
    }

    setPlayerListComponent(
      playersToBeRendered.map((player) => {
      return (
      <li key={player?.name}>
      <PlayerItem
        isInMiddleOfTurn={isInMiddleOfTurn}
        isCurrentPlayer={currentPlayerName === player?.name}
        ownerOfScreen={ownerOfScreen}
        name={player?.name}
        lives={player?.lives}/>
      </li>
        )})
    )
  }, [currentPlayerName, isInMiddleOfTurn, ownerOfScreen, players])

  return (
    <div style={{overflowY: 'clip'}}>
      {playerListComponent}
    </div>
  );
}