import { Card, CardContent, Typography, CardMedia } from "@mui/material";
import { motion } from "framer-motion";

import { BACKEND_BASE_URL, CARD_YEAR_FONT_SIZE_INCREMENT_IN_EM } from "../constants";
import { useState, useEffect } from "react";

export function RevealedCard(props: any) {
  const userLanguage = navigator.language.split('-')[0];

  const [cardImage, setCardImage] = useState(require(`../assets/loadingCardImage.jpg`));

  useEffect(() => {
    async function loadImage(cardId: string) {
      const imageUrl = `${BACKEND_BASE_URL}/static/${cardId}.jpg`;
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      setCardImage(url);
    }
    loadImage(props.cardId);
  }, [props.cardId])

  const transitionDelayToStart = 0.5
  const transitionDurationInSeconds = 4

  const fontSizeInEm = 0.45
  const yearFontSizeInEm = fontSizeInEm * CARD_YEAR_FONT_SIZE_INCREMENT_IN_EM

  const highlightedCardWidth = 15.5;
  
  const cardMaxHeight = 31.5;
  const highlightedCardMaxHeight = cardMaxHeight * 1.14;

  const cardAspectRatio = 2/3;

  const imageAspectRatio = 17/18;
  const highlightedImageMaxHeight = highlightedCardMaxHeight - 6.5;

  return (
  <motion.div
    style={{
      aspectRatio: `${cardAspectRatio}`,
      maxHeight: `${highlightedCardMaxHeight}vh`,
      width: `min(${highlightedCardWidth}vw, ${highlightedCardMaxHeight * cardAspectRatio}vh)`,
      borderRadius: '12px',
    }}
    initial="hidden"
    animate="visible"
    variants={{
      visible: {
        opacity: 1,
        transition: {
          delay: transitionDelayToStart,
          duration: transitionDurationInSeconds
        }
      },
      hidden: { opacity: 0 }
    }}
  >
    <Card 
      className="CardOnTimeline ColumnFlexWithCenteredItems"
      sx={{width: '100%', height: '100%'}}>
      <CardContent>
        <Typography gutterBottom fontSize={`${yearFontSizeInEm}em`}>
          <strong>{Math.abs(props.year)} {props.year < 0 ? 'AC' : ''}</strong>
        </Typography>

        <motion.div
          style={{
            width: `min(${highlightedCardWidth - 1}vw, ${highlightedCardMaxHeight * cardAspectRatio - 1}vh)`,
            filter: 'none',
            marginBottom: '0.5vh',
            aspectRatio: `${imageAspectRatio}`, 
            maxHeight: `${highlightedImageMaxHeight}vh`,
          }}
          initial="hidden"
          animate="visible"
          variants={{
            visible: {
              opacity: 1,
              transition: {
                delay: transitionDelayToStart,
                duration: transitionDurationInSeconds
              }
            },
            hidden: { opacity: 0 }
          }}
        >
        <CardMedia
          sx={{width: '100%', height: '100%'}}
          image={cardImage}
        />
        </motion.div>

        <Typography color="text.secondary" fontSize={`${fontSizeInEm}em`}>
          {userLanguage === 'pt' ? props.namePT : props.nameEN}
        </Typography>
      </CardContent>
    </Card>
  </motion.div>
  )
}