import { Card, CardContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

function PlayerList({ players }: { players: string[] }) {
  const { t } = useTranslation();
  return (
    <div>
      <Card sx={{ 
        background: 'rgba(0, 0, 0, 0.55)',
        fontSize: '2vmin',
        color: 'white',
        borderRadius: '20px'}}>
      <CardContent>
        <Typography gutterBottom>
          <strong>{t('waitingRoom.playersConnected')}</strong>
          {players.length === 0 && (
          <>
          <br/><br/><>❌</>
          </>)}
        </Typography>
        {players.map((player, idx) =>
          <li key={idx + player}>
          <p>{player}</p>
          </li>
        )}
      </CardContent>
      </Card>
    </div>  
  );
}

export default PlayerList;