import { Card, CardContent, Typography } from '@mui/material';
import PodiumStep from './PodiumStep'
import { useEffect, useState } from 'react';

export default function Podium({ podiumItems } : { podiumItems: string[] }) {
  const [podiumElement, setPodiumElement] = useState<JSX.Element[]>([]);
  useEffect(() => {
    const players = podiumItems.map((playerJson) => JSON.parse(playerJson))
    const maxScore = players.at(0).finalRound

    setPodiumElement(
      players.map((player, idx) => {
      return (
      <li key={'scitem'+idx}>
      <PodiumStep
        key={player.name}
        winner={player}
        rank={player.rank}
        maxScore={maxScore}/>
      </li>
      )})
    )
  }, [podiumItems])

  return (
    <div 
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '90vh'}}>
    <Card sx={{ 
      background: 'rgba(0, 0, 0, 0.5)',
      color: 'white',
      borderRadius: '20px',
      width: '30vw',
      height: '7vh',
    }}>
    <CardContent>
      <Typography gutterBottom variant='h4' fontSize={'3vmin'}>
        <strong>Placar final</strong>
      </Typography>
    </CardContent>
    </Card>
    <div
      style={{
        paddingBottom: '2vh',
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem',
        marginTop: '4vh',
        justifyContent: 'center',
        justifyItems: 'center',
        alignContent: 'center',
        alignItems: 'flex-start',
        borderLeft: '1px solid #e5e7eb',
        height: `calc(12vh * ${podiumItems.length})`,
        width: '50vw',
      }}
    >
      {podiumElement}
    </div>
    </div>
  )
}
