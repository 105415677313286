import { Card, Typography } from "@mui/material";
import { isOnlineMode } from "../utils";
import { useTranslation } from "react-i18next";

export function PlayerItem(props: any) {
  const { t } = useTranslation();

  const shouldHighlight = (props.isCurrentPlayer && props.isInMiddleOfTurn);
  const playerHasScreen = (props.ownerOfScreen === props.name);
  const isPlayerAlive = (props.lives !== 0);
  const fontSize = 'auto';

  const _isOnlineMode = isOnlineMode();

  return (
      <Card 
        className="PlayerInfo"
        sx={{
          width: shouldHighlight ? '108%' : '100%',
          overflow:'visible',
          backgroundColor: 
            shouldHighlight ? 'white' : 
            isPlayerAlive ? 'rgb(110,0,0)' : 'rgb(75, 75, 75)',
          color: shouldHighlight ? 'rgb(110,0,0)' : 'white',
          fontWeight: 'bold',
          borderTopRightRadius: '100vw',
          borderBottomRightRadius: '100vw',
          "&::after": {
            content: '""',
            marginLeft: 'auto',
            position: 'relative',
            left: '0.17em',
            borderTop: "10px solid transparent",
            borderBottom: "10px solid transparent",
            overflow: "visible",
            borderLeft: shouldHighlight ? "10px solid white" : "0px",
          }}}
        >
        <div style={{
          display: 'flex',
          width: '90%'
        }}>
          <Typography
            fontSize={fontSize}
            sx={{
              marginRight: 'auto',
              fontWeight: 'bold',
              paddingLeft: '1.5vw'}}
          >
            {props.name} {(playerHasScreen && shouldHighlight) && '(' + t('playerItem.yourTurn') + ')'} {(_isOnlineMode && playerHasScreen && !isPlayerAlive) && '(' + t('playerItem.youreOut') + ')'}
          </Typography>
          <Typography
            fontSize={fontSize}
            sx={{
              fontWeight: 'bold',
              marginLeft: 'auto'}}
          >
            ❤️{props.lives}
          </Typography>
        </div>
      </Card>
  )
}
