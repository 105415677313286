import { BACKEND_WS_URL } from "./constants"

export const isOnlineMode = () => window.location.pathname.startsWith('/game/online');
export const isMainView = () => window.location.pathname.startsWith('/game/online/main/');
export const isAdditionalView = () => window.location.pathname.startsWith('/game/online/table/');
export const isQuickGame = () => window.location.pathname.startsWith('/game/online/quick/');

export const getUrlForTableWsConnection = (tableId: string | undefined, playerId: string | undefined) => 
    isAdditionalView() ?
        BACKEND_WS_URL + `/tables/${tableId}/connect/additional-view/${playerId}` :
    isQuickGame() ?
        BACKEND_WS_URL + `/quick-game/${playerId}` :
    isOnlineMode() ?
        BACKEND_WS_URL + '/tables/new/online' :
        BACKEND_WS_URL + '/tables/new/party'

export const getUrlForPlayerWsConnection = (tableId: string | undefined, playerId: string | undefined) =>
    BACKEND_WS_URL + '/tables/' + tableId + '/connect/' + playerId