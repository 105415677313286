// animation
export const TIME_FOR_CARD_FADE_IN_MILLISECONDS = 400
export const DELAY_TO_START_RENDERING_TIMELINE_ON_FIRST_GUESS = 700
export const DELAY_BEFORE_RENDERING_NEXT_TIMELINE_WINDOW = 300
export const DEFAULT_WAIT_TIME_TO_SHOW_NEXT_WORD_IN_MS = 500

// notifications
export const AUTO_HIDE_DURATION_FOR_PLAY_RESULT_NOTIFICATION_IN_MS = 6000
export const AUTO_HIDE_DURATION_FOR_APPLIED_TIMEOUT_NOTIFICATION_IN_MS = 6000
export const AUTO_HIDE_DURATION_FOR_START_OF_ROUND_MESSAGE_IN_MS = 3300

// websocket
export const INTERVAL_BETWEEN_RECONNECT_TRIES_IN_MILLISECONDS = 2500
export const MAX_NUMBER_OF_RECONNECT_ATTEMPTS = 10

// proportions
export const CARD_YEAR_FONT_SIZE_INCREMENT_IN_EM = 1.38
export const CARD_YEAR_FONT_SIZE_INCREMENT_IN_REM = 1.18

// game
export const GAME_STATUS = 
{
  NotStarted: "notStarted",
  Running: "running",
  Finished: "finished"
}

// configs
export const DEVELOPMENT_MODE: 
  boolean = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

// urls
export const BACKEND_WS_URL = DEVELOPMENT_MODE ?
  'ws://localhost:8080' :
  'wss://p01--saidera-backend--dd7m5y7qsn4v.code.run'

export const BACKEND_BASE_URL = DEVELOPMENT_MODE ?
  'http://localhost:8080' :
  'https://p01--saidera-backend--dd7m5y7qsn4v.code.run'