import * as ReactDOM from 'react-dom/client';

import { BrowserRouter, Route, Routes }  from 'react-router-dom';

import reportWebVitals from './reportWebVitals';

import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';

import './i18n';

import Home from './pages/Home';
import Table from './pages/Table';
import PlayerScreen from './pages/PlayerScreen';
import JoinTable from './pages/JoinTable';
import PageNotFound from './pages/PageNotFound';
import './style/index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const theme = createTheme({
  palette: {
    primary: {main: '#ffffff'}
  },
});

root.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <Routes>
        <Route path="/" Component={Home} />
        <Route path="/game/party" Component={Table} />
        <Route path="/game/online/table/:tableId/player/:playerId" Component={Table} />
        <Route path="/game/online/main/:playerId" Component={Table} />
        <Route path="/game/online/quick/player/:playerId" Component={Table} />
        <Route path="/play/table/:tableId" Component={JoinTable} />
        <Route path="/play/table/:tableId/player/:playerId" Component={PlayerScreen} />
        <Route path="*" Component={PageNotFound} />
      </Routes>
    </BrowserRouter>
  </ThemeProvider>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
