export enum EventNames {
	endOfPlayerTurn             = "endOfPlayerTurn",
	requestToPlay               = "requestToPlay",
	startOfRound                = "startOfRound",
	startGame                   = "startGame",
	playerDisconnected          = "playerDisconnected",
	showPlayerCard              = "showPlayerCard",
	appliedTimeoutPenalty       = "appliedTimeoutPenalty",
	ack                         = "ack",
	ping                        = "ping",
	createNewTable              = "createNewTable",
	updatePlayersOnWaitingRoom  = "updatePlayersOnWaitingRoom",
	showPartialTimeline         = "showPartialTimeline",
	gameFinished                = "gameFinished",
	playerChoice                = "playerChoice",
	playerOut                   = "playerOut",
	showPreviousWindow          = "showPreviousWindow",
	showNextWindow              = "showNextWindow",
	updateActivePlayers         = "updateActivePlayers",
	awaitingPlayerReconnection  = "awaitingPlayerReconnection",
	playerReconnected           = "playerReconnected",
	unableToJoinTable           = "unableToJoinTable",
	intermediateGuessMade       = "intermediateGuessMade",
	awaitingPlayerGuess         = "awaitingPlayerGuess",
	connectedAdditionalView     = "connectedAdditionalView"
}