import { useEffect, useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useSound from 'use-sound';
import { Snackbar, Alert, Button, Typography, Card, CardContent, LinearProgress, IconButton } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { SubdirectoryArrowLeft, SubdirectoryArrowRight } from '@mui/icons-material';

import startNewRoundSound from '../assets/soundEffects/startNewRound.wav'

import "../style/Home.css";
import "../style/PlayerScreen.css";
import { EventNames } from '../models/EventNames'
import { CardModel } from '../models/CardModel';
import { CardOnPlayerView } from '../components/CardOnPlayerView';
import { BACKEND_WS_URL, INTERVAL_BETWEEN_RECONNECT_TRIES_IN_MILLISECONDS, MAX_NUMBER_OF_RECONNECT_ATTEMPTS } from '../constants';

function PlayerScreen() {
  const eventBus = document;
  const { tableId, playerId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [playStartNewRoundSound] = useSound(startNewRoundSound);

  const [connectionWithServerOpen, setConnectionWithServerOpen] = useState(false);
  const [playButtonsEnabled, setPlayButtonsEnabled] = useState(false);
  const [hasWSConnectionPreviouslyOpened, setHasWSConnectionPreviouslyOpened] = useState(false);
  const [playerMadeRightChoice, setPlayerMadeRightChoice] = useState(true)
  const [isGuessResultVisible, setIsGuessResultVisible] = useState(false);
  const [timeToPlay, setTimeToPlay] = useState(false);
  const [showErrorOnSlideWindow, setShowErrorOnSlideWindow] = useState(false);
  const [slideWindowErrorText, setSlideWindowErrorText] = useState("");
  const [pivotCard, setPivotCard] = useState<CardModel | undefined>();
  const [playerCard, setPlayerCard] = useState<CardModel | undefined>();
  const [isPlayerAlive, setIsPlayerAlive] = useState(true);
  const [playerFinalRound, setPlayerFinalRound] = useState(1);

  const [blinkingEmoji, setBlinkingEmoji] = useState('⚪');

  const [shouldRedirectHome, setShouldRedirectHome] = useState(false);
  const [errorMessageWhenRedirecting, setErrorMessageWhenRedirecting] = useState( t('errorMessages.errorConnectingToServer') )

  const { sendJsonMessage, lastMessage, readyState } = 
    useWebSocket(BACKEND_WS_URL + '/tables/' + tableId + '/connect/' + playerId, {
      shouldReconnect: () => isPlayerAlive,
      retryOnError: true,
      reconnectAttempts: MAX_NUMBER_OF_RECONNECT_ATTEMPTS,
      reconnectInterval: INTERVAL_BETWEEN_RECONNECT_TRIES_IN_MILLISECONDS,
      onReconnectStop: () => setShouldRedirectHome(true),
      onClose: (e) => {
        console.log('Websocket connection closed!', e)
        setConnectionWithServerOpen(false)
      },
      onError: (e) => {
        console.log('Error on Websocket connection!', e)
        if (!hasWSConnectionPreviouslyOpened) {
          setShouldRedirectHome(true)
        }
      }
    });

  useEffect(() => {
    const redirectHomeWithError = () => {
      navigate("/", {state: {error_message : errorMessageWhenRedirecting, type : 'Erro'}})
    }
    if(shouldRedirectHome) {
      redirectHomeWithError()
    }
  }, [errorMessageWhenRedirecting, navigate, shouldRedirectHome])

  useEffect(() => {
    eventBus.addEventListener("custom:playStartNewRoundSound", () => {
      playStartNewRoundSound();
    })
  }, [eventBus, playStartNewRoundSound])


  const processRequestToPlay = (pivotCard: CardModel) => {
    setPivotCard(pivotCard)
    setPlayButtonsEnabled(true)
    setTimeToPlay(true)
  }

  const processShowPlayerCard = (card: CardModel) => {
    setPlayerCard(card)
  }

  const onPlayMade = (beforePivotCard: boolean) => {
    sendJsonMessage({type: 'playerChoice', data: {'beforePivotCard': beforePivotCard.toString()}});
    setPlayButtonsEnabled(false)
  }

  const onShowPreviousWindow = () => {
    sendJsonMessage({type: 'showPreviousWindow', data: {}});
  }

  const onShowNextWindow = () => {
    sendJsonMessage({type: 'showNextWindow', data: {}});
  }

  const showGuessResult = () => {    
    setIsGuessResultVisible(true)
  }

  const hideGuessResult = () => {
    setIsGuessResultVisible(false)
  }

  const onCloseErrorOnSlideWindow = () => {
    setShowErrorOnSlideWindow(false)
  }

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      setConnectionWithServerOpen(true);
      setHasWSConnectionPreviouslyOpened(true)
    }
  }, [readyState]);

  useEffect(() => {
    const showErrorOnShowingPrevWindow = () => {
      setSlideWindowErrorText( t('playerScreen.noPreviousCards') )
      setShowErrorOnSlideWindow(true)
    }
  
    const showErrorOnShowingNextWindow = () => {
      setSlideWindowErrorText( t('playerScreen.noNextCards') )
      setShowErrorOnSlideWindow(true)
    }

    const sendPong = () => {
      sendJsonMessage({type: 'pong', data: {}});
    }

    const processNonAck = (evt: any) => {    
      let evtName = evt.type
      if(evtName !== EventNames.ping) console.log(evt)

      switch(evtName) {
        case "ping": {
          setBlinkingEmoji(blinkingEmoji => {
            if (blinkingEmoji === '⚪') return '⚫'
            return '⚪'
          })
          sendPong()
          break;
        }
        case EventNames.requestToPlay: {
          const pivotCard = JSON.parse(evt.data.pivotCard)
          processRequestToPlay(pivotCard)
          break;
        }
        case EventNames.showPlayerCard: {
          const playerCard = JSON.parse(evt.data.playerCard)
          setTimeToPlay(true)
          eventBus.dispatchEvent(new CustomEvent("custom:playStartNewRoundSound"));
          processShowPlayerCard(playerCard)
          break;
        }
        case EventNames.playerOut: {
          const player = JSON.parse(evt.data.player)
          setIsPlayerAlive(false)
          setPlayerFinalRound(player.finalRound)
          break;
        }
        case EventNames.appliedTimeoutPenalty: {
          // const player = JSON.parse(evt.data.player)
          // TODO: show visual feedback that player took timeout penalty
          setTimeToPlay(false)
          break;
        }
        case EventNames.unableToJoinTable: {
          const reason = evt.data.reason
          setErrorMessageWhenRedirecting(t('errorMessages.'+reason))
          setShouldRedirectHome(true)
          sendJsonMessage({
            type: 'ack',
            data: {"refersTo": EventNames.unableToJoinTable}})
          break;
        }
      }
    }

    const processAck = (evt: any) => {
      let evtName = evt.data.refersTo
      switch(evtName) {
        case EventNames.playerChoice: {
          const madeRightChoice = JSON.parse(evt.data.response.madeRightChoice)
          setTimeToPlay(false)
          setPlayerMadeRightChoice(madeRightChoice)
          showGuessResult()
          break;
        }
        case EventNames.showPreviousWindow: {
          showErrorOnShowingPrevWindow()
          break;
        }
        case EventNames.showNextWindow: {
          showErrorOnShowingNextWindow()
          break;
        }
      }
    }

    const processEvent = (eventJson: MessageEvent<any>) => {
      let event = JSON.parse(eventJson.data)
      let evtType = event.type 
      switch(evtType) {
        case EventNames.ack: {
          processAck(event)
          break;
        }
        default: {
          processNonAck(event)
          break;
        }
      }
    }
    
    if (lastMessage !== null) {
      processEvent(lastMessage)
    }
  }, [eventBus, lastMessage, sendJsonMessage, t])

  return (
    /* TODO: add player life to player view. add on bottom between prev and next buttons */
    <div className='PlayerScreen'>
      {isPlayerAlive ? (
      <div>
        {isGuessResultVisible && (
          playerMadeRightChoice ? (
            <Snackbar 
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              open={true} autoHideDuration={4000} onClose={hideGuessResult}>
              <Alert severity="success">
              {t('playerScreen.rightGuess')}
              </Alert>
            </Snackbar>
          ) : (
            <Snackbar 
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              open={true} autoHideDuration={4000} onClose={hideGuessResult}>
              <Alert severity="error">
              {t('playerScreen.wrongGuess')}
              </Alert>
            </Snackbar>
          ))}
        {connectionWithServerOpen ? 
        timeToPlay ? 
        (
          <div className='PlayerScreenDuringPlay'>
            <Card sx={{ 
              background: 'rgba(0, 0, 0, 0.5)',
              color: 'white',
              borderRadius: '20px',
              paddingTop: '2vh'
            }}>
            <CardContent>
              <Typography gutterBottom variant='h4' fontSize={'3vmin'}>
                <strong>{t('playerScreen.gameQuestion')}</strong>
              </Typography>
            </CardContent>
            </Card>
            <div className="PlayButtons">
            {/* Boolean on onClick = 'beforePivotCard' */}
            <CardOnPlayerView
              cardId={playerCard?.id}
              namePT={playerCard?.namePT}
              nameEN={playerCard?.nameEN}
              year={playerCard?.year} 
              description={playerCard?.description} 
              shouldHideYear={true}
              onClick={() => onPlayMade(true)}
              />
            <CardOnPlayerView 
              cardId={pivotCard?.id}
              namePT={pivotCard?.namePT}
              nameEN={pivotCard?.nameEN}
              year={pivotCard?.year} 
              description={pivotCard?.description} 
              onClick={() => onPlayMade(false)}/>
            </div>
            <Snackbar 
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={showErrorOnSlideWindow} autoHideDuration={4000} onClose={onCloseErrorOnSlideWindow}>
              <Alert severity="error">
                {slideWindowErrorText}
              </Alert>
            </Snackbar>
            <div className="ChangeWindowButtons">
              <Button 
                variant='outlined'
                id='showPreviousWindow'
                startIcon={<SubdirectoryArrowLeft/>}
                disabled={!playButtonsEnabled}
                onClick={() => onShowPreviousWindow()}>
                  <Typography fontSize={'1vh'}>
                    {t('playerScreen.seePrevious')}
                  </Typography>
              </Button>
              <Button 
                variant='outlined'
                id='showNextWindow'
                endIcon={<SubdirectoryArrowRight/>}
                disabled={!playButtonsEnabled}
                onClick={() => onShowNextWindow()}>
                  <Typography fontSize={'1vh'}>
                  {t('playerScreen.seeNext')}
                  </Typography>
              </Button>
            </div>
          </div>
        ) : (
          <Card sx={{ 
            background: 'rgba(0, 0, 0, 0.55)',
            fontSize: '2vmin',
            color: 'white',
            borderRadius: '20px',
            paddingTop: '1vh'}}>
          <CardContent>
            <Typography gutterBottom>
            {blinkingEmoji} {t('playerScreen.waitForYourTurn')} {blinkingEmoji}
            </Typography>
          </CardContent>
          </Card>
        ) : (
          /* connection with server is closed */
          <Card sx={{ 
            background: 'rgba(0, 0, 0, 0.55)',
            fontSize: '2vmin',
            color: 'white',
            borderRadius: '20px'}}>
          <CardContent>
            <Typography gutterBottom> 
            {t('playerScreen.connectingToServer')}
            </Typography>
            <LinearProgress 
              sx={{mt: '2vh'}}
              color='inherit' />
          </CardContent>
          </Card>
        )}
      </div>) : (
        <p>{t('playerScreen.youSurvivedUntil')} {playerFinalRound}!</p>
      )}
      <IconButton
        color="inherit"
        onClick={() => navigate('/')}
      >
        <HomeIcon sx={{fontSize: '5vh'}} />
      </IconButton>
    </div>
  );
}

export default PlayerScreen;
