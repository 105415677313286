import { useNavigate, useParams } from "react-router-dom";
import { TextField, Button, Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import "../style/Home.css";

function JoinTable() {
    const { tableId } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleLogin = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      const userId = data.get('userId');
      navigate(`/play/table/${tableId}/player/${userId}`, {state: {type : 'Redirect'}});
    }

    return (
        <Box
          id='joinTableScreen'
          className="ColumnFlexWithCenteredItems"
          component="form" onSubmit={handleLogin} noValidate>
        <TextField 
          id="userId"
          name="userId"
          label="Digite seu nome"
          variant="filled" 
          sx={{
            "& .MuiInputLabel-root": {color: 'white'},
            "& .MuiFilledInput-input": {border: '1px solid white',
            borderRadius: 1.5},
            input: {color: 'white'}
        }}/>
        <Button id='connectToRoom' type="submit" variant="outlined">
          <Typography fontSize={'1.5em'}>
            {t('home.confirm')}
          </Typography>
        </Button>
        </Box>
    );
}

export default JoinTable;
